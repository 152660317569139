import { TypographicTokensModule } from '@intm-ui/typographic-tokens/typographic-tokens.module';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'intm-dropdown-button',
    templateUrl: './dropdown-button.component.html',
    standalone: true,
    imports: [
        TypographicTokensModule,
        TranslateModule
    ]
})
export class DropdownButtonComponent {
    showSelect: boolean                                 = false;
    @Input('options') options: Array<optionInterface>   = [];
    @Input('titlemobile') titlemobile: string           = '';
    @ViewChild('dropdownContainer') dropdownContainer: ElementRef | undefined;

    toggle() {
        this.showSelect = !this.showSelect;
    }

    close () {
        this.showSelect = false;
    }

    closeOnBlur(event: FocusEvent) {
        if (this.dropdownContainer)
        {
            if (!this.dropdownContainer.nativeElement.contains(event.relatedTarget))
            {
                this.close();
            }
        }
    }
}

export interface optionInterface { title: string, callback: Function, icon: string }