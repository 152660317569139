<div class="flex justify-center items-center">
    <input
      type="date"
      class="w-full px-16 rounded-8 font-helvetica-now-regular font-regular text-14  xs:text-16 leading-20 -tracking-0-5 {{control && control.touched && control.invalid ? 'focus:outline-none shadow-semantic-danger': 'shadow-stroke-low focus:outline-none focus:shadow-semantic-primary'}} {{ disabled ? 'cursor-not-allowed bg-alt' : '' }} placeholder:text-content-low xs:h-48"
      [ngClass]="{'h-40': size ==='normal', 'h-48': size === 'large'}"
      [placeholder]="placeholder"
      [min]="minDate"
      [max]="maxDate"
      [value]="dateValue"
      [disabled]="disabled"
      (input)="onDateChange($event)"
    />
  </div>
  
  
  