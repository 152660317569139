import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { Switch } from "@intm-ui/switch";

import {
    ButtonPrimary,
    ButtonSecondary,
    ButtonTertiary,
    ButtonDanger,
    ButtonPrimaryMana
} from "@intm-ui/buttons";
import {
    EmailInputComponent,
    NumberInputComponent,
    PasswordInputComponent,
    PasswordInputManaComponent,
    TextInputComponent,
    DateInputComponent
} from "@intm-ui/inputs";
import { Link } from "@intm-ui/link";
import { Checkbox } from "@intm-ui/checkbox";
import { ModalModule } from "@intm-ui/modals";
import { SelectModule } from "@intm-ui/select";
import { SimpleLabelComponent } from "@intm-ui/labels";
import { RadioComponent } from "@intm-ui/radio-buttons";
import { FileUploadComponent, FileUploadSimpleComponent } from "@intm-ui/file-upload";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MessageHelperErrorComponent } from "@intm-ui/message-helper";
import { TagComponent } from "@intm-ui/tags/tag.component";
import { TooltipComponent } from "@intm-ui/tooltip";
import { TypographicTokensModule } from "@intm-ui/typographic-tokens/typographic-tokens.module";
import { TextareaComponent } from "@intm-ui/text-area";
import { LoginGuard } from "../guards/login.guard";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { SkeletonComponent } from "@app/component/utils/skeleton/skeleton.component";
import { SearchInputComponent } from "@intm-ui/search-input";
import { TopbarComponent } from "@app/component/layouts/topbar/topbar.component";
import { ParseNamePipe, isLangESPiPe, isLangITPiPe, isLangPTPiPe, TimeElapsedPipe, GetFlagPipe, isDomainIntmPiPe, isDomainManaPiPe } from "src/app/pipes";
import { ActivatedRoute, Router } from "@angular/router";
import { LANG_DEFINITION, ORIGIN_APP } from "@app/utils/const";
import { LangService, OriginService, StorageService } from "@app/service/utils";
import { AuthService } from "@app/service/auth";
import { MenuListModule } from "@intm-ui/menu-list/menu-list.module";
import { DocumentsList } from "@intm-ui/file-upload/documents-list";
import { InsuranceStatusComponent } from "src/app/components/utils/insurance-status";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SimpleTopBarComponent } from "src/app/components/utils/simple-top-bar";
import { LogoLinkComponent } from "@app/component/utils/logo-link";
import { DropdownButtonComponent } from "@intm-ui/dropdown-button";
import { DatePickerComponent } from "@intm-ui/date-picker/date-picker.component";


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    imports: [
        HttpClientModule,
        TypographicTokensModule,
        Switch, ButtonPrimary, ButtonSecondary, ButtonTertiary, ButtonDanger, ButtonPrimaryMana,
        EmailInputComponent, NumberInputComponent, TextInputComponent, DateInputComponent,
        PasswordInputComponent, PasswordInputManaComponent, Checkbox, SelectModule, ModalModule,
        SimpleLabelComponent, RadioComponent,
        FileUploadComponent, MessageHelperErrorComponent,
        TooltipComponent,TagComponent,Link,FormsModule, ReactiveFormsModule,
        CommonModule,TranslateModule, SkeletonComponent, SearchInputComponent, TopbarComponent,
        TextareaComponent, MenuListModule,
        DocumentsList, FileUploadSimpleComponent, InsuranceStatusComponent,
        DatePickerComponent,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SimpleTopBarComponent,
        LogoLinkComponent,
        DropdownButtonComponent
    ],
    declarations: [
        ParseNamePipe,
        isLangESPiPe,
        isLangITPiPe,
        isLangPTPiPe, 
        isDomainIntmPiPe, 
        isDomainManaPiPe,
        TimeElapsedPipe,
        GetFlagPipe
    ],
    exports: [
        HttpClientModule,
        TypographicTokensModule,
        Switch, ButtonPrimary, ButtonSecondary, ButtonTertiary, ButtonDanger, ButtonPrimaryMana,
        EmailInputComponent, NumberInputComponent, TextInputComponent, DateInputComponent,
        PasswordInputComponent, PasswordInputManaComponent, Checkbox, SelectModule, ModalModule,
        SimpleLabelComponent, RadioComponent,
        FileUploadComponent, MessageHelperErrorComponent,
        TooltipComponent,TagComponent,Link, FormsModule, ReactiveFormsModule,
        CommonModule, TranslateModule, SkeletonComponent, SearchInputComponent, TopbarComponent,
        ParseNamePipe, isLangESPiPe, isLangITPiPe, isLangPTPiPe, isDomainIntmPiPe, isDomainManaPiPe, TimeElapsedPipe,
        TextareaComponent, MenuListModule,
        DocumentsList, FileUploadSimpleComponent, InsuranceStatusComponent,
        SimpleTopBarComponent,
        LogoLinkComponent,
        GetFlagPipe, DatePickerComponent, DropdownButtonComponent
    ],
    providers: [
        LoginGuard,
        DatePipe
    ]
})
export class SharedModule {
    constructor (
        private storage: StorageService,
        private activate: ActivatedRoute,
        private loginService: AuthService,
        private router: Router,
        private translate: TranslateService,
        private originService: OriginService
    ) {
        const langPath: string = window.location.pathname.split('/')[1];
        if (langPath) {
            switch(langPath) { // En caso de que exista lo enviamos al swtich
                case LANG_DEFINITION.es:
                case LANG_DEFINITION.it:
                case LANG_DEFINITION.pt:
                    // Si el param de lang conicide con los que tenemos en el switch
                    // Llamamos la funcion que setea el lang enviandole el params['lang']
                    if ( langPath !== LangService.getLang() ) {
                        this.endSessionRedirectLogin(langPath);
                    }
                    this.setLang(langPath)
                    break;
                default:
                    // this.endSessionRedirectLogin(LangService.getLang());
                    // En caso de que no coincide necesitamos obtener el lang desde el servicio del LangService
                    this.setLang(LangService.getLang());
                    break;
            }
        } else {
            // En caso de que no exista un parametro en la url obtenemos el lang del servicio del LangService
            this.setLang(LangService.getLang());
        }

        // Verificamos si existe el parametro origen en la url
        // y si esta tiene como valor app_mana o app_im
        this.activate.queryParams.subscribe(
            params => {
                if (params["origen"] && (params["origen"] === ORIGIN_APP.MANA || params["origen"] === ORIGIN_APP.INTERMUNDIAL)){
                    this.originService.setOrigin(params["origen"]);
                } else {
                    // En caso de que no exista algun origen se setea uno por defecto que seria el de
                    // Intermundial
                    this.originService.setOrigin(ORIGIN_APP.INTERMUNDIAL);
                }
            }
        );
    }

    /**
     * Funcion que se encarga de setear el idioma al servicio de traducciones y guardar el lang en la sesion
     * @param lang
     */
    setLang(lang: string) {
        localStorage.setItem('lang', lang);
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
    }

    userIsAutenticated(): boolean {
        if (this.loginService.getUserInfo()) {
            return true;
        }
        return false;
    }

    endSessionRedirectLogin(lang: string) {
        if ( this.userIsAutenticated() ) {
            const userInfo: any = this.loginService.getUserInfo();
            this.loginService.logout().subscribe(() =>{});
            localStorage.clear();
            sessionStorage.clear();
            this.router.navigate([`/${lang}/login`])
        }
    }
}
