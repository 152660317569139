import { of, throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { find, isEqual } from 'lodash';
import { LoaderService, NotificationService, StorageService, LangService } from '../utils';
import { getRandomBackground } from "src/app/utils";
import { TranslateService } from '@ngx-translate/core';

/*
 * CustomQueryEncoderHelper
 * Fix plus sign (+) not encoding, so sent as blank space
 * See: https://github.com/angular/angular/issues/11058#issuecomment-247367318
 */
class CustomQueryEncoderHelper extends HttpUrlEncodingCodec {
    override encodeKey(k: string): string {
        k = super.encodeKey(k);
        return k.replace(/\+/gi, '%2B');
    }
    
    override encodeValue(v: string): string {
        v = super.encodeValue(v);
        return v.replace(/\+/gi, '%2B');
    }
}

interface IOptions {
    headers?: HttpHeaders,
    params?: HttpParams,
    withCredentials?: boolean
}

@Injectable()
export class ApiV5AppService {
    private XAPI_KEY: {[key: string]: string } = {
        'x-api-key': 'd6ddf0ebc76614b134bf9d8908ba3a33944dc34affb6839c2a9bb4192d56e5bc'
    };

    private options: IOptions = {
        headers: this.makeHeader(),
        withCredentials: false
    };
    private cache: any[] = [];

    sessionExpiredMessage: string = '';

    constructor(
        private http: HttpClient,
        private storage: StorageService,
        private notificationService: NotificationService,
        private loaderService: LoaderService,
        private router: Router,
        private translate: TranslateService
    ) { 
        this.translate.get('login.session_caducada').subscribe(
            text => {
                this.sessionExpiredMessage = text;
            }
        );
    }

    /**
     * Funcion que se encarga de agregar los tokens de sesion a la cabecera de la peticion
     * @param headers
     */
    private setToken(headers: HttpHeaders) {
        const TOKEN: string = this.storage.get("sesion.token");
        const TOKEN_TYPE: string = "Bearer";

        if (TOKEN) { // Verificamos que las constantes tenga valor
            headers.set("Authorization", `${TOKEN_TYPE} ${TOKEN}`);
        }

        return headers; // Retornamos la cabecera con los tokens ya seteados
    }

    /**
     * Funcion que genera la cabecera de tipo application/json
     */
    private makeHeader(language?: string): HttpHeaders {

        const headers = new HttpHeaders(
            {
                "Content-Type": "application/json",
                ...this.XAPI_KEY,
            }
        );

        this.setToken(headers); // Agregamos en la cabecera los tokens necesarios

        return headers;

    }
    private addOriginInURL(url: string) {
        return `${url}?origin=APP`;
    }

    /**
     * Método get, recibe el path y parametros opcionales que puedan ser necesarios para la petición
     * @param url dirección final de la url de la petición
     * @param params parametros añadidos a la url final
     * @param cache permite guardar o no guardarlo en la cache
     * @param options configuracion de las opciones
     * @param responseIsBlob bandera para añadir responseContentBlob
     */
    get(url: string, params?: any, cache?: boolean, options?: any, language?:string): Observable<any> {
        url = this.addOriginInURL(url);

        this.options = { headers: this.makeHeader(language), withCredentials: false };

        if (cache) {
            let result: any;
            //  VALIDA SI TIENE PARAMETROS PARA BUSCAR COINCIDENCIA EN LA CACHE
            //  DE LO CONTRARIO BUSCA SOLO POR EL PATH
            if (params) {
                result = find(this.cache, function (o) { return isEqual(o.path, url) && isEqual(o.params, params) });
            } else {
                result = find(this.cache, function (o) { return isEqual(o.path, url) && isEqual(o.params, params) });
            }

            if (result) {
                return of(result.result);
            }
        }

        if (options) {
            this.options = { ...this.options, ...options };
        }

        let httpParams = new HttpParams({encoder: new CustomQueryEncoderHelper()});
        if (params) {
            Object.keys(params).forEach(key => {
                httpParams.set(key, params[key]);
            });
            this.options.params = httpParams;
        }

        return this.http.get(url, this.options).pipe(
            map((response: any) => {
                // Verificamos que el content-type sea application/json
                if (response.headers.get('content-type') === 'application/json') {
                    response = response.json(); // Parseamos la respuesta a json
                }

                if (cache) {
                    let result: any;

                    //  VALIDA SI TIENE PARAMETROS PARA BUSCAR EN LA CACHE
                    //  DE LO CONTRARIO BUSCA SOLO POR EL PATH
                    if (params) {
                        result = find(this.cache, function (o) { return isEqual(o.path, url) && isEqual(o.params, params) });
                    } else {
                        result = find(this.cache, function (o) { return isEqual(o.path, url) && isEqual(o.params, params) });
                    }

                    if (!result) {
                        //  VALIDA SI TIENE PARAMETROS PARA GUARDARLOS EN CACHE
                        //  DE LO CONTRARIO SOLO LO GUARDA POR EL PATH
                        if (params) {
                            this.cache.push({ path: url, params: params, result: response })
                        } else {
                            this.cache.push({ path: url, result: response })
                        }
                    }
                }
                return response;
            }),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Método post, recibe el path y el body
     * @param url dirección final de la url de la petición
     * @param body objeto Json u objeto a pasar por JSON.stringify para la petición
     * @param options configuracion de las opciones
     * @param urlDeleteCache borra de la cache la url seleccionada
     */
    post(url: string, body: any, options?: any, urlDeleteCache?: string): Observable<any> {
        url = this.addOriginInURL(url);

        this.options = { headers: this.makeHeader(), withCredentials: false };

        if (options && options['Content-Type'] === "multipart/form-data") {
            this.options.headers = new HttpHeaders({ ...this.XAPI_KEY });
            this.options.headers = this.setToken(this.options.headers);
        }

        return this.http.post(url, body, this.options).pipe(
            map((response: any) => {
                if (urlDeleteCache) {
                    this.deleteCache(urlDeleteCache);
                }
                return response.json();
            }),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Método put, recibe el path, el body y un parametro indicando si es necesario hacerle un JSON.stringify
     * @param url string: dirección final de la url de la petición
     * @param body objeto Json u objeto a pasar por JSON.stringify para la petición
     * @param headers configuracion de cabeceras
     * @param urlDeleteCache borra de la cache la url seleccionada
     */
    put(url: string, body: any, options?: any, urlDeleteCache?: string, params?: any): Observable<any> {
        url = this.addOriginInURL(url);
        console.log(url)
        
        let headers = this.makeHeader();

        if (options?.headers)
        {
            headers = { ...headers, ...options.headers };
        }

        let httpParams = new HttpParams();

        if (params)
        {
            for (const key in params) {
                httpParams = httpParams.set(key, params[key]);
            }
        }

        return this.http.put(url, body, { headers, params: httpParams }).pipe(
            map((response: any) => {
                if (urlDeleteCache) {
                    this.deleteCache(urlDeleteCache);
                }
                switch(typeof(response))
                {
                    case 'string':
                        return response;
                    default:
                        return response.json();
                }
            }),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Método delete, recibe el path para la petición
     * @param url dirección final de la url de la petición
     * @param urlDeleteCache borra de la cache la url seleccionada
     */
    delete(url: string, urlDeleteCache?: string, params?: any): Observable<any> {
        url = this.addOriginInURL(url);

        this.options = {
            headers: this.makeHeader(),
            withCredentials: false
        }

        let httpParams = new HttpParams({encoder: new CustomQueryEncoderHelper()});
        if (params) {
            Object.keys(params).forEach(key => {
                httpParams = httpParams.set(key, params[key]);
            });
            this.options.params = httpParams;
        }
        
        return this.http.delete(url, this.options).pipe(
            map((response: any) => {
                if (urlDeleteCache) {
                    this.deleteCache(urlDeleteCache);
                }
                return response;
            }),
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * Busca en la cache para borrar esa url en concreto
     * @param url direccion a borrar en la cache
     */
    private deleteCache(url: string): void {
        url = this.addOriginInURL(url);
        const result: any = find(this.cache, { path: url });
        if (result) {
            const index = this.cache.indexOf(result);
            if (index) {
                this.cache.splice(index, 1);
            }
        }
    }

    /**
     * Funcion para solicitar nuevo Token
     */

    private handleError(error: any) {
        if (error.status) {
            switch (error.status) {
                case 401:
                    this.sessionExpired();
                    break;
                default:
                    this.notificationService.error(error.json().message, 4000);
                    this.loaderService.hide();
                    break;
            }
        } else {
            const urlError = error._body.target.__zone_symbol__xhrURL;
            const errorMessage = 'A network error occurred. This could be a CORS issue or a dropped internet connection. ' + 
                                'It is not possible for us to know.<br> XMLHttpRequest at ' + urlError;
            this.notificationService.error(errorMessage, 4000);
        }

        return throwError(error.json());
    }

    clearCache() {
        this.cache = [];
    }

    /**
     * Función para mostrar mensaje de session expirada y redigir al login
     */
    private sessionExpired(): void {
        this.notificationService.info(this.sessionExpiredMessage);
        this.storage.clear();
        const background: string = getRandomBackground();
        this.storage.set('background', background);
        this.router.navigate([`${LangService.getLang()}/login`]);
    }

}
