export const CREDENTIALS_INTERTECH = {
    username: 'safer_publico@intermundial.es',
    password: 'safer123'
}

export const CREDENTIALS_VECI = {
    usuario: 'vecisiniestros@intermundial.es', 
    password: 'SgMM5139'
}

export const CREDENTIALS_LOGITRAVEL = {
    usuario: 'siniestroslogi@intermundial.es', 
    password: '29z5Hd'
}

export const CREDENTIALS_SERVISEGUR = {
    usuario: 'tsocial@servisegur.com',
    password: 'h7qLr728'
}