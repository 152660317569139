import { Component, Input, Output, EventEmitter, OnInit, forwardRef, ViewEncapsulation, inject, Injector } from "@angular/core";
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, NgModel } from "@angular/forms";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "intm-date-picker",
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./date-picker.component.html"
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  private injector = inject(Injector);
  
  public control!: FormControl;
  public dateValue: string = '';

  @Input() placeholder: string = 'Seleccione una fecha';
  @Input() minDate!: string;
  @Input() maxDate!: string;
  @Input() disabled: boolean = false;
  @Input() size: string = 'normal';

  @Output() dateChange: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });
    if (ngControl instanceof NgModel) {
      this.control = ngControl.control;
      ngControl.control.valueChanges.subscribe((value) => {
        if (ngControl.model !== value || ngControl.viewModel !== value) {
          ngControl.viewToModelUpdate(value);
        }
      });
    } else if (ngControl instanceof FormControlDirective) {
      this.control = ngControl.control;
    } else if (ngControl instanceof FormControlName) {
      const container = this.injector.get(ControlContainer).control as FormGroup;
      this.control = container.controls[ngControl.name || ''] as FormControl;
    } else {
      this.control = new FormControl();
    }
  }

  private onChange = (value: any) => {};
  private onTouched = () => {};

  set value(val: string) {
    this.dateValue = val;
    this.onChange(val);
    this.onTouched();
  }

  get value(): string {
    return this.dateValue;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.dateValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDateChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.dateChange.emit(this.dateValue);
    if (this.control) {
      this.control.markAllAsTouched();
    }
  }
}

