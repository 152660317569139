import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    forwardRef,
    ViewEncapsulation,
    inject,
    Injector
} from "@angular/core";
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroup,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel,
} from "@angular/forms"
import { CommonModule } from "@angular/common"
import { InputType } from "../input.interface";
import { SimpleLabelComponent } from "@intm-ui/labels"
import { MessageHelperErrorComponent } from "@intm-ui/message-helper"

interface options {
    text: string,
    value: any,
    disable?: boolean
}

@Component({
    standalone: true,
    selector: "intm-date-input",
    imports: [
        CommonModule,
        SimpleLabelComponent,
        MessageHelperErrorComponent
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => DateInputComponent)
        }
    ],
    encapsulation: ViewEncapsulation.None,
    templateUrl: "./date-input.component.html",
    styles: [`
        .custom-date-input {
            -moz-appearance: textfield;
            -webkit-appearance: none;
            appearance: none;
        }

        .custom-date-input::-webkit-inner-spin-button,
        .custom-date-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }
        `]
})
export class DateInputComponent implements InputType, OnInit, ControlValueAccessor {

    private injector = inject(Injector);

    //default atribute for input
    public type: "number" = "number";
    public control!: FormControl;

    //inputs atribute
    @Input() name = ""
    @Input() id = ""
    @Input() size: "normal" | "large" = "normal"
    @Input() placeholder = ""
    @Input() disabled = false;
    //input for label component
    @Input() label = ""
    @Input() value: any = undefined;
    @Input() index: string = '';
    @Input() options: Array<options>    = [];
    minValue: number = 0;
    maxValue: number = 100;
    @Input() errorClass: boolean = false;

    //events of  the input
    @Output() changeEvent = new EventEmitter<string>();

    ngOnInit(): void {
        const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });
        if (ngControl instanceof NgModel) {
            // ⬇ Grab the host control
            this.control = ngControl.control;

            // ⬇ Makes sure the ngModel is updated
            ngControl.control.valueChanges.subscribe((value) => {
                if (ngControl.model !== value || ngControl.viewModel !== value) {
                    ngControl.viewToModelUpdate(value);
                }
            });

        } else if (ngControl instanceof FormControlDirective) {
            this.control = ngControl.control;

        } else if (ngControl instanceof FormControlName) {
            const container = this.injector.get(ControlContainer).control as FormGroup;
            this.control = container.controls[ngControl.name ? ngControl.name: ''] as FormControl;

        } else {
            this.control = new FormControl();
        }
        this.setMinValueByOptions();
        this.setMaxValueByOptions();
    }

    public regOnChange=(_: any) => {};
    public regOnTouched=(_: any) => {};

    set Value(val: string) {
        this.value = val;
        this.regOnChange(val);
        this.regOnTouched(val);
    }

    registerOnChange(fn: any): void {
        this.regOnChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.regOnTouched = fn;
    }


    writeValue(value: string): void {
        this.value = value;
    }

    /**
     * @description This method is called when the input value changes. It emits the new value through the valueChange event emitter.
     * @returns void
     * @param value
     */
    public onChanged($event: Event) {
        const inputElement = $event.target as HTMLInputElement;
        const typeEvent = $event.type;
        const inputValue = inputElement.value;
        //si la longitud del valor es menor al longitud del valor minimo que se retorne antes de hacer la validacion
        if (typeEvent !='blur' && inputValue.length < this.minValue.toString().length) {
            return;
        }

    
        // Convierte el valor ingresado a número
        const numericValue = parseFloat(inputValue);
    
        // Validar el rango (min y max)
        if (
            (this.minValue !== undefined && numericValue < this.minValue) ||
            (this.maxValue !== undefined && numericValue > this.maxValue)
        ) {
            // Limpiar el valor si no está dentro del rango permitido
            inputElement.value = '';
            this.value = '';
        } else {
            let dateValue = inputValue;
            if (inputValue.length == 1) {
                dateValue = '0' + inputValue;
            }
            this.value = dateValue; // Asigna el valor ingresado si es válido
            inputElement.value = dateValue; // Asigna el valor ingresado si es válido
        }
    
        // Notifica cambios a través de los métodos registrados
        this.regOnChange(this.value);
        this.changeEvent.emit(this.value);
    
        // Marca como tocado
        if (this.control) {
            this.control.markAsTouched();
        }
    }

    setDisabledState?(isDasabled: boolean): void {
        this.disabled = isDasabled
    }

    public clearValue() {
        this.value = '';
        this.regOnChange('');
        this.changeEvent.emit('');
        //limiar el valor del input
    }

    /**
     * @description This method is called when the input value changes. It emits the new value through the valueChange event emitter.
     */
    private setMinValueByOptions() {
        if (this.options.length > 0 && this.options[0].value.length < 3) {
            this.minValue = this.options[0].value;
        }else if(this.options.length > 0){
            this.minValue = this.options[this.options.length -1].value;
        }
    }

    /**
     * @description This method is called when the input value changes. It emits the new value through the valueChange event emitter.
     */
    private setMaxValueByOptions() {
        if (this.options.length > 0 && this.options[0].value.length < 3) {
            this.maxValue = this.options[this.options.length - 1].value;
        }else if(this.options.length > 0){
            this.maxValue = this.options[0].value;
        }
    }

    /**
     * function to validate the input value
     * @param event 
     */
    public onKeyPress(event: KeyboardEvent) {
        const char = event.key;
        if(!/^\d$/.test(char)){
            event.preventDefault();
        }
    }

}
