import { Injectable, signal, computed, Input } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    #loading = signal<boolean>(false);

    public loaderImage: string = 'assets/images/logos/loadingIM.gif';

    public loading = computed(() => this.#loading());

    constructor() {}

    private setLoading(loading: boolean) {
        this.#loading.set(loading);
    }
    
    show() {
        this.setLoading(true);
    }

    hide() {
        this.setLoading(false);
    }

    resetLoader() {
        this.loaderImage = 'assets/images/logos/loadingIM.gif';
    }
}
