<div class="fixed inset-0 z-[50] bg-overlay fade-in hidden {{ showSelect ? 'xs:block' : ''}}">
</div>
<div class="relative group"
    tabindex="0" (blur)="closeOnBlur($event)" #dropdownContainer
>
    <div class="active:opacity-50 bg-base border border-semantic-primary disabled:border-content-high disabled:cursor-not-allowed disabled:opacity-50 duration-150 ease-in focus:outline-none h-40 hover:opacity-75 m:h-48 px-16 py-0 rounded-8 text-content-high transition-all w-full hover:cursor-pointer flex gap-8 items-center justify-center"
        (click)="toggle()"
    >
        <ng-content></ng-content>
        @if (showSelect)
        {
            <div class="xs:hidden">
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path d="M6 15L12 9L18 15"
                            stroke="#131D34"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                    />
                </svg>
            </div>
        }
        @if (!showSelect)
        {
            <div class="xs:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M6.5 9L12.5 15L18.5 9" stroke="#131D34" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        }
    </div>
    <div class="{{showSelect ? 'block' : 'hidden' }} absolute z-[100] w-full rounded-b-8 bg-base shadow-low py-8 mt-4 xs:bottom-0 xs:fixed xs:left-0 xs:rounded-b-0 xs:rounded-t-16 xs:pb-24 xs:pt-0 xs:mt-0">
        <div class="gap-16 justify-between pt-24 px-16 pb-24 hidden xs:flex">
            <intm-text-high-xs>
                {{ titlemobile }}
            </intm-text-high-xs>
            <img src="assets/images/icons/close.svg" (click)="toggle()"/>
        </div>
        @if (options.length) {
            @for (option of options; track $index) {
                <div class="flex gap-8 items-center px-16 py-12 hover:cursor-pointer active:opacity-50 hover:opacity-75 hover:bg-alt xs:tou"
                    (click)="option.callback(); close()"
                >
                    <intm-text-low-s [color]="'mid'">
                        {{ option.title | translate }}
                    </intm-text-low-s>
                    <img src="{{ option.icon }}" alt="icon"/>
                </div>
            }
        }
    </div>
</div>