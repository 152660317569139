@if(type){
    @if(label){
        <intm-simple-label [for]="name" [disabled]="disabled" >{{label}}</intm-simple-label>
    }
    <input
        [placeholder]="placeholder"
        class="custom-date-input w-full px-16 rounded-8 font-helvetica-now-regular font-regular text-14  xs:text-16 leading-20 -tracking-0-5 {{(control && control.touched && control.invalid || errorClass) ? 'focus:outline-none shadow-semantic-danger': 'shadow-stroke-low focus:outline-none focus:shadow-semantic-primary'}} {{ disabled ? 'cursor-not-allowed bg-alt' : '' }} placeholder:text-content-low xs:h-48"
        [ngClass]="{'h-40': size ==='normal', 'h-48': size === 'large'}"
        [type]="type"
        [name]="name"
        [id]="id"
        [value]="value"
        [disabled]="disabled"
        (change)="onChanged($event)"
        (keyup)="onChanged($event)"
        (blur)="onChanged($event)"
        (keypress)="onKeyPress($event)"
        tabindex="{{index}}"
        inputmode="numeric"
        [min]="minValue"
        [max]="maxValue"
    />
    @if(control && control.touched && control.invalid){
        <intm-message-helper-error [control]="control"></intm-message-helper-error>
    }
}
