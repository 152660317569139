import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiV5Service } from "../api/apiV5.service";
import { ApiV5IntertechService } from "../api/apiV5.intertech.service";
import { ApiV3Service } from '../api/apiv3.service';


const API_AC = environment.API_AC;
const BASE_PATH = 'https://upload.box.com/api/2.0/';

@Injectable(
    {providedIn: 'root'}
)
export class BoxApiService {
    constructor(
        private api: ApiV5Service,
        private apiIntertech: ApiV5IntertechService,
        private apiV3: ApiV3Service
    ) {}

    getFolder(): Observable<any> {
        const url: string = `${API_AC}/api/claims/v5/claim/box/folder`;
        return this.api.get(url);
    }

    getFolderIntertech(): Observable<any> {
        const url: string = `${API_AC}/aks/api/box/v5/tmp/folder`;
        return this.apiIntertech.get(url);
    }
  

    getToken(): Observable<any> {
        const url: string = `${API_AC}/api/claims/v5/claim/box/token`;
        return this.api.get(url);
    }

    getTokenIntertech(): Observable<any> {
        const url: string = `${API_AC}/aks/api/box/v5/token`;
        return this.apiIntertech.get(url);
    }

    makeUrlUploadFile() {
        const url: string = `${API_AC}/api/claims/v5/claim/box/document?origin=SF`;
        return url;
    }

    makeUrlUploadFileIntertech() {
       const url:string = `${API_AC}/aks/api/box/v5/files/content?origin=SF`;
       return url;
    }

    getFolderVeci(): Observable<any> {
        const url: string = `/es/box/tmp/carpeta`;
        return this.apiV3.get(url);
    }

    getTokenVeci(): Observable<any> {
        const url: string = `/es/box/token`;
        return this.apiV3.get(url);
    }

    getFolderTravelconcept(): Observable<any> {
        const url: string = `/mana-es/box/tmp/carpeta`;
        return this.apiV3.get(url);
    }

    getTokenTravelconcept(): Observable<any> {
        const url: string = `/mana-es/box/token`;
        return this.apiV3.get(url);
    }

    makeUrlUploadFileVeci() {
        const url: string = `${BASE_PATH}files/content`;
        return url;
    }
}