import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { NotificationService } from '../utils';
@Injectable({
  providedIn: 'root'
})
export class ApiV3Service {
  private BASE_URL = environment.baseUrl;
  private sendToken = false;
  constructor(
    private _http: HttpClient,
    private _notificationService: NotificationService,
    private _router: Router
  ) { 
    this.handleError = this.handleError.bind(this);
  }
  get(afterString?: string, params?: Record<string, any>) {
    const _url = this.makeUrl(afterString as string);
    this.sendToken = true;
    return this._http.get(_url, this.buildHeader(params))
      .pipe(
        catchError(this.handleError)
      );
  }
  post(resource: any, afterString?: string) {
    let _url: string = '';
    if (afterString) {
      this.sendToken = afterString !== 'usuarios/sesiones';
      _url = this.makeUrl(afterString);
    }
    return this._http.post(_url, JSON.stringify(resource), this.buildHeader())
      .pipe(
        catchError(this.handleError)
      );
  }
  delete(afterString?: string, id?: string) {
    let _url = afterString ? this.makeUrl(afterString) : '';
    if (id) {
      _url += `${id}/`;
    }
    this.sendToken = true;
    return this._http.delete(_url, this.buildHeader())
      .pipe(
        catchError(this.handleError)
      );
  }
  private buildHeader(params?: Record<string, any>) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (this.sendToken) {
      const TOKEN = localStorage.getItem('token');
      if (TOKEN) headers = headers.set('Authorization', `Bearer ${TOKEN}`);
    }
    let httpOptions = { headers };
    if (params) {
      const httpParams = new HttpParams({ fromObject: params });
      httpOptions = {
        ...httpOptions,
        //params: httpParams
      };
    }
    return httpOptions;
  }
  private makeUrl(path: string) {
    return `${this.BASE_URL}${path}`;
  }
  private handleError(error: HttpErrorResponse) {
    const objError = error.error.error;
    let message;
    console.error('Error:', error);
    switch (objError?.status_code) {
      case 500:
        message = objError.details?.[0]?.message || 'Error en el sistema, contacte con el administrador.';
        this._notificationService.error(message);
        break;
      case 403:
        message = 'No tiene permisos para acceder al recurso solicitado.';
        this._notificationService.error(message);
        break;
      case 401:
        localStorage.removeItem('intm_session');
        localStorage.removeItem('token');
        this._router.navigate(['/claims']);
        message = 'Su sesión ha caducado, por favor vuelva a loguearse.';
        this._notificationService.error(message);
        break;
      default:
        const message_g = `${objError?.message} Referencia: ${objError?.reference} Código: ${objError?.code}`;
        if (objError.details?.validations) {
          let html = `<div style='padding: 20px 0px;'><ul style='list-style-type:none;'><li style='padding:5px 0px;'> ${message_g} </li>`;
          objError.details.validations.forEach((validation: any) => {
            html += `<li style='padding:5px 0px;'>${validation.message}</li>`;
          });
          html += `</ul></div>`;
          this._notificationService.error(html);
        } else {
          this._notificationService.error(message_g);
        }
    }
    if (error.error instanceof ErrorEvent) {
      console.error('ha ocurrido un error', error.error.message);
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}